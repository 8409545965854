export const FONTSIZE_28 = '28px';
export const FONTSIZE_20 = '20px';
export const FONTSIZE_16 = '16px';
export const FONTSIZE_14 = '14px';
export const FONTSIZE_12 = '12px';
export const FONTSIZE_10 = '10px';

export const zIndexValues = {
  ACTION_BUTTON: 1,
  DISPLAY_TABLE: 10,
  DROP_DOWN_MENU: 10,
  HEADER: 15,
  MODAL: 15,
  SIDEBAR: 14,
  EDIT_MODULE_DRAWER: 8,
  EDIT_SUB_MODULE_DRAWER: 8,
  PAGE_HEADER: 20,
  ADD_ASSESSMENT_DRAWER: 8,
  ADD_COURSE_DRAWER: 8,
  EDIT_DETAILS_DRAWER: 11,
  COURSE_DELETE_POP: 30,
  AUDIO_PLAYER: 30,
  EDIT_WORKEXP_DRAWER: 11,
  VIEW_CUSTOMER_COURSE_DRAWER: 11,
  CUSTOMER_FILTER_DRAWER: 11,
  PAYMENT_FILTER_DRAWER: 11,
  BACK_ARROW: 18,
  CALCULATE_EARNING_CONTAINER: 17,
};

export const colorCodes = {
  COMPLETE: '#32b237',
};
