import React from 'react';
import styled from 'styled-components';

const StyledVideo = styled.video`
  border-radius: 12px;
`;
const VideoPlayer = ({ videoLink }) => {
  return <StyledVideo width={'621px'} src={videoLink} controls />;
};

export default VideoPlayer;
