import AUTH_BG from '../images/authBg.png';
import CALCULATE_EARNINGS from '../../assets/images/calculateEarning.png';
import PAN from '../images/pan.png';

const IMAGES = {
  AUTH_BG,
  CALCULATE_EARNINGS,
  PAN,
};

export default IMAGES;
