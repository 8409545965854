export const ADDRESS_MIN_LENGTH = 5;
export const ADDRESS_MAX_LENGTH = 100;

export const CITY_MIN_LENGTH = 3;
export const CITY_MAX_LENGTH = 28;

export const MIN_EXPERIENCE = 0;
export const MAX_EXPERIENCE = 40;

export const PIN_LENGTH = 6;

export const STATES_LIST = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Lakshadweep',
  'Delhi',
  'Puducherry',
  'Ladakh',
  'Jammu and Kashmir',
];

export const DETAILS_DRAWER_STRUCTURE = {
  address: '',
  city: '',
  state: '',
  country: 'India',
  pin: '',
  experience: 0,
  introVideoLink: '',
  education: '',
};

export const DETAILS_ERROR_STRUCTURE = {
  address: false,
  city: false,
  state: false,
  pin: false,
  experience: false,
  education: false,
};

export const EDUCATION_DETAILS_LIST = [
  'No Formal Education',
  'Primary School (Class 1-5)',
  'Middle School (Class 6-8)',
  'Secondary School (Class 9-10)',
  'Higher Secondary (Class 11-12)',
  'Diploma (Polytechnic, Engineering, etc.)',
  'Undergraduate Degree',
  'Postgraduate Degree',
  'Ph.D.',
  'Others',
];
export const RUPEE_SYMBOL = '₹';
