import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import CustomCTA from '../CustomCTA';

import { ModalContext } from '../../context/ModalProvider';
import RemarksModal from './RemarksModal';

import { useSnackbar } from 'notistack';
import { formatDate } from '../../utils/helper';
import DocumentStatus from '../customerDetails/DocumentStatus';
import { VERIFICATION_STATUS_MAP } from '../../constants/verification';
import usePermission from '../../hooks/usePermission';

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const P = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: ${(props) => props.$fontSize};
  font-style: normal;
  font-weight: ${(props) => props.$fontWeight};
  line-height: ${(props) => props.$lineHeight};
  color: ${(props) => (props.color ? props.color : '#000000')};
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '0px')};
`;

const FlexContainer = styled.div`
  font-family: Poppins;
  width: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.$flexDirection ? props.$flexDirection : 'row'};
  gap: 8px;
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : 'flex-start'};
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : 'flex-start'};
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '0px')};
`;

const RemarkWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  width: calc(100% - 40px);
  padding: 20px;
  margin-top: 10px;
`;

const RemarkCard = ({ remark }) => {
  const remarkStatus =
    VERIFICATION_STATUS_MAP?.CURRENT_STATES?.[remark?.remarkStatus];
  return (
    <RemarkWrapper>
      <FlexContainer $alignItems="center">
        <P $fontSize="14px" $fontWeight="600" $lineHeight="normal">
          {remark?.agentEmail}
        </P>
        <P
          color="#606C85"
          $fontSize="14px"
          $fontWeight="400"
          $lineHeight="normal"
        >
          {formatDate(remark?.updatedAt, 'DD MMM YYYY - hh:mm a')}
        </P>
        {remarkStatus ? (
          <>
            <P
              color="#606C85"
              $fontSize="14px"
              $fontWeight="400"
              $lineHeight="normal"
            >
              Action:
            </P>
            <DocumentStatus status={remarkStatus} />
          </>
        ) : null}
      </FlexContainer>
      <P
        color="#606C85"
        $fontSize="16px"
        $fontWeight="400"
        $lineHeight="normal"
        $marginTop="10px"
      >
        {remark?.message}
      </P>
    </RemarkWrapper>
  );
};

const Remarks = ({
  remarks,
  postRemarksError,
  onSubmit,
  isError = false,
  heading = 'Add Remarks',
  text = 'Remarks',
  placeholder = 'Enter your remarks here. ',
  showText = true,
  showHeading = true,
  showCloseIcon = true,
  permission,
}) => {
  const { displayModal, updateModal, closeModal } = useContext(ModalContext);
  const { hasPermission } = usePermission();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isError)
      enqueueSnackbar(
        `Failed to post remarks. error : ${postRemarksError?.message}`,
        {
          variant: 'error',
        },
      );
    updateModal(
      <RemarksModal
        isLoading={false}
        onSubmit={onSubmit}
        heading={heading}
        text={text}
        showText={showText}
        showHeading={showHeading}
        placeholder={placeholder}
        showCloseIcon={showCloseIcon}
      />,
    );
  }, [isError]);

  const handleSubmit = async (message, customProps = {}) => {
    if (typeof onSubmit === 'function') {
      updateModal(
        <RemarksModal
          isLoading
          onSubmit={handleSubmit}
          heading={heading}
          text={text}
          showText={showText}
          showHeading={showHeading}
          placeholder={placeholder}
          showCloseIcon={showCloseIcon}
        />,
      );
      onSubmit({ message });
      enqueueSnackbar('You successfully added a remark ', {
        variant: 'success',
      });
    }
  };

  return (
    <Wrapper>
      <FlexContainer $justifyContent="space-between" $alignItems="center">
        <P $fontSize={'16px'} $fontWeight={'600'} $lineHeight={'normal'}>
          Remarks
        </P>
        <CustomCTA
          onClick={() => {
            displayModal(
              <RemarksModal
                onSubmit={handleSubmit}
                heading={heading}
                text={text}
                showText={showText}
                showHeading={showHeading}
                showCloseIcon={showCloseIcon}
                placeholder={placeholder}
              />,
              { showCloseIcon },
            );
          }}
          showIcon
          title="Add Remarks"
          showSecondary={true}
          color="#ffffff"
          bgColor="#677995"
          border="1px solid #CDD4DF"
          isPermitted={permission && hasPermission(permission)}
        />
      </FlexContainer>
      <FlexContainer $flexDirection="column">
        {remarks?.length > 0 ? (
          remarks?.map((remark) => <RemarkCard remark={remark} />)
        ) : (
          <P $fontSize={'14px'} $fontWeight={'400'} $lineHeight={'normal'}>
            No remarks added
          </P>
        )}
      </FlexContainer>
    </Wrapper>
  );
};

export default Remarks;
