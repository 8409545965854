import { Skeleton } from '@mui/material';
import React, { lazy, Suspense, useEffect, useState, useRef } from 'react';
import CustomCTA from '../components/CustomCTA';
import DisplayTable from '../components/DisplayTable';
import { employers } from '../mockData';
import styleComponents from '../style/pageStyle';
import ICONS from '../assets/icons';
import CreateAccDrawer from '../components/employers/CreateAccDrawer';
import CalculateEarnings from '../components/employers/CalculateEarnings';
import { useNavigate } from 'react-router-dom';
import { useGetAllEmployer, usePostAddEmployer } from '../apis/queryHooks';
import { useSnackbar } from 'notistack';
import { companyTypeMap } from '../constants/employer';
import { generateRandomString } from '../utils/helper';
import CreateAccDrawerNext from '../components/employers/CreateAccDrawerNext';
import BoxLoader from '../components/common/BoxLoader';
import usePermission from '../hooks/usePermission';
import {
  EMPLOYER_MODULE_PERMISSIONS,
  PAGE_PERMISSIONS,
} from '../constants/permissions';
const Pagination = lazy(
  () => import('../components/atom/tableComponents/Pagination'),
);

const {
  Wrapper,
  Top,
  Bottom,
  HeaderWrap,
  Header,
  HeaderTitle,
  HeaderDesc,
  AnimatedBox,
  Details,
  TableDiv,
} = styleComponents();

const Employers = () => {
  const { hasPermission } = usePermission();
  const isMounted = useRef(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showActionsPanel, setShowActionsPanel] = useState(true);
  const [employerTableHeadersData, setEmployerTableHeadersData] = useState([]);
  const [employerData, setEmployerData] = useState([]);
  const [totalEmployer, setTotalEmployer] = useState(0);
  const [actionIndex, setActionIndex] = useState('');
  const [actionOpen, setActionOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openCreateAccDrawer, setOpenCreateAccDrawer] = useState(false);
  const [openCreateAccDrawerNext, setOpenCreateAccDrawerNext] = useState(false);
  const [createAccObj, setCreateAccObj] = useState({
    companyName: '',
    companyType: '',
    companySize: '',
    email: '',
    potentialEarnings: '',
  });
  const [createAccErr, setCreateAccErr] = useState();
  const [isCalculateEarningsVisible, setIsCalculateEarningsVisible] =
    useState(false);

  const {
    data: allEmployerData,
    isLoading: allEmployerDataLoading,
    isFetching: allEmployerDataFetching,
    isError: isAllEmployerDataError,
    error: allEmployerDataError,
    refetch: refetchAllEmployer,
  } = useGetAllEmployer({
    currentPage,
    itemsPerPage,
  });

  const {
    mutate: postAddEmployerMutate,
    status: postAddEmployerStatus,
    error: postAddEmployerError,
  } = usePostAddEmployer();

  const handleCreateAccount = () => {
    setIsCalculateEarningsVisible(true);
  };

  useEffect(() => {
    setTotalItems(allEmployerData?.totalAgencies || 10);
  }, [allEmployerData]);

  useEffect(() => {
    if (postAddEmployerStatus === 'success') {
      setIsCalculateEarningsVisible(false);
      setOpenCreateAccDrawerNext(false);
      refetchAllEmployer();
      setCreateAccObj({});
      setCreateAccErr();
      clearFields();
      enqueueSnackbar('Employer added successfully', {
        variant: 'success',
      });
    } else if (postAddEmployerStatus === 'error') {
      if (postAddEmployerError?.response?.data?.error?.message) {
        enqueueSnackbar(postAddEmployerError?.response?.data?.error?.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Failed to add employer', {
          variant: 'error',
        });
      }
      setIsCalculateEarningsVisible(false);
      // setOpenCreateAccDrawerNext(false);
      refetchAllEmployer();
      setCreateAccObj({});
      setCreateAccErr();
      clearFields();
    }
  }, [postAddEmployerStatus]);

  const handleCreateNewAccount = () => {
    const companyType = companyTypeMap[createAccObj.companyType];
    const payload = {
      userContact: {
        email: createAccObj.email,
      },
      sourceType: 'OPS',
      companyType: companyType,
      companySize: createAccObj.companySize,
      userType: 'STAFFING_AGENCY',
      password: generateRandomString(),
      name: createAccObj.companyName,
      potentialEarnings: createAccObj?.potentialEarnings,
    };
    postAddEmployerMutate(payload);
  };

  const clearFields = () => {
    setCreateAccObj({
      companyName: '',
      companyType: '',
      companySize: '',
      email: '',
    });
    setCreateAccErr();
  };

  const handleNewAccountClick = () => {
    clearFields();
    setOpenCreateAccDrawer(true);
  };

  const handleOpenCreateAccountModal = () => {
    // clearFields();
    setIsCalculateEarningsVisible(false);
    setOpenCreateAccDrawer(false);
    setOpenCreateAccDrawerNext(true);
  };

  const headerKeys = Array.from(
    employerTableHeadersData.map((item) => item.key),
  );

  function createData(userDetails) {
    return headerKeys?.map((item) => userDetails[item] ?? '-----');
  }

  const onShowSizeChange = (pageSize) => {
    if (itemsPerPage !== pageSize) {
      setCurrentPage(1);
      setItemsPerPage(pageSize);
    }
  };

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleRowClick = (index) => {
    if (!hasPermission(PAGE_PERMISSIONS?.VIEW_EMPLOYER_DETAILS)) return;
    navigate(`/employers/${employerData[index]?._id}`);
  };
  const handleViewDetailsClick = (e) => {
    e.stopPropagation();
    navigate(`/employers/${employerData[actionIndex]?._id}`);
  };

  const arrBtn = [
    {
      text: 'View Details',
      icon: ICONS.EYE,
      active: true,
      isVisible: true,
      color: '#586276',
      onClick: handleViewDetailsClick,
      permission: PAGE_PERMISSIONS?.VIEW_EMPLOYER_DETAILS,
    },
  ];

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (arrBtn.length > 0) {
      const hasAnyPermission = arrBtn.some((btn) =>
        hasPermission(btn.permission),
      );
      setShowActionsPanel(hasAnyPermission);
    }
  }, [arrBtn, hasPermission]);

  useEffect(() => {
    if (!allEmployerDataLoading) {
      setEmployerTableHeadersData(allEmployerData?.headers || []);
      setEmployerData(allEmployerData?.response || []);
      setTotalEmployer(allEmployerData?.totalAgencies || 0);
    }
  }, [allEmployerDataLoading, allEmployerDataFetching, allEmployerData]);

  const tableHeaders = Array.from(
    employerTableHeadersData.map((item) => item.value),
  );

  const rows = Array.from(employerData.map((item) => createData(item)));

  return (
    <>
      {isCalculateEarningsVisible ? (
        <CalculateEarnings
          createAccObj={createAccObj}
          handleOpenCreateAccountModal={handleOpenCreateAccountModal}
          setIsCalculateEarningsVisible={setIsCalculateEarningsVisible}
          setOpenCreateAccDrawer={setOpenCreateAccDrawer}
          setCreateAccObj={setCreateAccObj}
          openCreateAccDrawer={openCreateAccDrawer}
          setCreateAccErr={setCreateAccErr}
        />
      ) : (
        <Wrapper>
          <Top>
            <HeaderWrap>
              <Header>
                <HeaderTitle>All Account</HeaderTitle>
                <HeaderDesc>{`Total: ${totalEmployer}`}</HeaderDesc>
              </Header>
              <CustomCTA
                onClick={handleNewAccountClick}
                title={'New Account'}
                showIcon={true}
                color={'#FFF'}
                bgColor={'#141482'}
                border={'1px solid #CDD4DF'}
                isPermitted={hasPermission(
                  EMPLOYER_MODULE_PERMISSIONS?.UPDATE_EMPLOYERS,
                )}
              />
            </HeaderWrap>

            <Suspense
              fallback={
                <AnimatedBox>
                  {[1, 2, 3, 4, 5].map((item, idx) => {
                    return <Skeleton animation="wave" height={70} key={idx} />;
                  })}
                </AnimatedBox>
              }
            >
              <Details>
                <TableDiv>
                  {!(allEmployerDataLoading || allEmployerDataFetching) ? (
                    <DisplayTable
                      tableId={'employersTable'}
                      rows={rows}
                      headers={tableHeaders}
                      headersType={Array.from(
                        employerTableHeadersData?.map((item) => item.type),
                      )}
                      showActionsPanel={showActionsPanel}
                      onClickFn={handleRowClick}
                      arrBtn={arrBtn}
                      actionIndex={actionIndex}
                      setActionIndex={setActionIndex}
                      actionOpen={actionOpen}
                      setActionOpen={setActionOpen}
                      tableData={employers}
                      arrBtnRight={'80px'}
                    />
                  ) : (
                    <BoxLoader size={5} />
                  )}
                </TableDiv>
              </Details>
            </Suspense>
          </Top>

          <Bottom>
            <Suspense
              fallback={
                <AnimatedBox>
                  {[1].map((item, idx) => {
                    return <Skeleton animation="wave" height={70} key={idx} />;
                  })}
                </AnimatedBox>
              }
            >
              <Pagination
                onShowSizeChange={onShowSizeChange}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalItems={totalItems}
                setTotalItems={setTotalItems}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                arrowBg={'#ebeff6'}
                isFlexColumn={false}
                isBottom={true}
                setOpenDropdown={setOpenDropdown}
                openDropdown={openDropdown}
                handleDropdown={handleDropdown}
              />
              <CreateAccDrawer
                open={openCreateAccDrawer}
                toggleDrawer={setOpenCreateAccDrawer}
                handleCreateAccount={handleCreateAccount}
                createAccObj={createAccObj}
                createAccErr={createAccErr}
                setCreateAccObj={setCreateAccObj}
                setCreateAccErr={setCreateAccErr}
                setIsCalculateEarningsVisible={setIsCalculateEarningsVisible}
              />
              <CreateAccDrawerNext
                open={openCreateAccDrawerNext}
                toggleDrawer={setOpenCreateAccDrawerNext}
                handleCreateNewAccount={handleCreateNewAccount}
                createAccObj={createAccObj}
                createAccErr={createAccErr}
                setCreateAccObj={setCreateAccObj}
                setCreateAccErr={setCreateAccErr}
                setIsCalculateEarningsVisible={setIsCalculateEarningsVisible}
              />
            </Suspense>
          </Bottom>
        </Wrapper>
      )}
    </>
  );
};

export default Employers;
