import React from 'react';
import styled from 'styled-components';
import VideoPlayer from '../common/VideoPlayer';

const Wrapper = styled.div`
  padding: 20px;
`;
const P = styled.p`
  white-space: nowrap;
  color: ${(props) => props?.$color};
  font-family: Poppins;
  font-size: ${(props) => (props?.$fontSize ? props.$fontSize : '14px')};
  font-style: normal;
  font-weight: ${(props) => props?.$fontWeight};
  line-height: ${(props) => props?.$lineHeight};
  margin-bottom: 20px;
`;

const IntroVideoModal = ({ videoLink }) => {
  return (
    <Wrapper>
      <P
        $color="#000"
        $fontSize="24px"
        $fontWeight={'600'}
        $lineHeight={'normal'}
      >
        Intro Video
      </P>
      <VideoPlayer videoLink={videoLink} />
    </Wrapper>
  );
};

export default IntroVideoModal;
