import { destroyCookie } from 'nookies';
import * as Sentry from '@sentry/react';

export const errorHandler = (error) => {
  Sentry.captureException(error);
  if (error?.response?.status === 401) {
    // logout
    destroyCookie(null, 'accessToken');
    destroyCookie(null, 'userId');
    destroyCookie(null, 'guestToken');
    window.location.href = '/login';
  }
};
