import React, { useEffect, useState, lazy, Suspense } from 'react';
import styled from 'styled-components';
import ICONS from '../assets/icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const EmployerDetailsTab = lazy(
  () => import('../components/employers/EmployerDetailsTabs'),
);
import { useGetEmployerDetails } from '../apis/queryHooks';
import BusinessVerificationPage from '../components/employers/BusinessVerificationPage';
const CustomerPageHeader = lazy(
  () => import('../components/customerDetails/CustomerPageHeader'),
);

const Wrapper = styled.div`
  margin: 61px 0 0 265px;
  min-height: calc(100vh - 3.5rem);
  background-color: #f4f6fa;
  padding: 16px 40px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
`;

const Img = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
`;

const EmployerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const employerId = location.pathname.substring(11, location.pathname.length);
  const [showBusinessVerificationPage, setShowBusinessVerificationPage] =
    useState(false);
  const [pageRoute, setPageRoute] = useState('');

  const {
    data: employerData,
    isLoading: isEmployerDataLoading,
    isFetching: isEmployerDataFetching,
    isError: isEmployerDataError,
    error: employerDataError,
    refetch: refetchEmployerData,
  } = useGetEmployerDetails(employerId);

  useEffect(() => {
    if (isEmployerDataError) {
      enqueueSnackbar('Error while fetching employer details', {
        variant: 'error',
      });
    }
  }, [isEmployerDataError]);

  const handleLeftArrow = () => {
    // navigate(`/employers`);
    navigate(-1);
  };

  if (showBusinessVerificationPage) {
    return (
      <BusinessVerificationPage
        employerData={employerData}
        setShowBusinessVerificationPage={setShowBusinessVerificationPage}
        isEmployerDataLoading={isEmployerDataLoading}
        isEmployerDataFetching={isEmployerDataFetching}
        employerDataError={employerDataError}
        refetchEmployerData={refetchEmployerData}
        pageRoute={pageRoute}
        setPageRoute={setPageRoute}
      />
    );
  }

  return (
    <Wrapper>
      <Header>
        <Left onClick={() => handleLeftArrow()}>
          <Img
            src={ICONS.LEFT_ARROW_BLACK}
            alt="leftArrowBlack"
            width={'24px'}
            height={'24px'}
          />
        </Left>
      </Header>
      <Suspense fallback={<div></div>}>
        <CustomerPageHeader
          heading={employerData?.companyRegisteredName ?? '-----'}
          subHeading={`Account ID: ${employerData?._id ?? '-----'}`}
        />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <EmployerDetailsTab
          currentIndex={employerData}
          employerDataLoading={isEmployerDataLoading}
          employerDataFetching={isEmployerDataFetching}
          employerDataError={isEmployerDataError}
          refetchEmployerData={refetchEmployerData}
          setShowBusinessVerificationPage={setShowBusinessVerificationPage}
          setPageRoute={setPageRoute}
        />
      </Suspense>
    </Wrapper>
  );
};

export default EmployerDetails;
